import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BobHttpService } from '../../shared/services/bob-http.service';
import { LinkRequest, LinkRequestOID } from '../models/LinkRequest';
import type { LinkRequestCommand } from '../models/LinkRequestCommand';
import { LinkRequestsResponse } from './LinkRequestsResponse';

const LINK_REQUEST_ENDPOINT = '/link-request-api/public/v1';

@Injectable()
export class HttpLinkRequestsGateway {
    constructor(private httpService: BobHttpService) {}

    getLinkRequests(): Observable<LinkRequestsResponse> {
        return this.httpService.get(`${LINK_REQUEST_ENDPOINT}/requests?state=[EXPIRED]`);
    }

    createLinkRequest({ siteOwnerEmail, gatewayId }: LinkRequestCommand): Observable<LinkRequest> {
        return this.httpService.post(`${LINK_REQUEST_ENDPOINT}/gateways/${gatewayId}/requests`, {
            siteOwnerEmail,
        });
    }

    resendLinkRequest(linkRequestOID: LinkRequestOID): Observable<LinkRequest> {
        return this.httpService.post(`${LINK_REQUEST_ENDPOINT}/requests/${linkRequestOID}/duplicate`, null);
    }

    deleteLinkRequest(linkRequestOID: LinkRequestOID): Observable<void> {
        return this.httpService.delete(`${LINK_REQUEST_ENDPOINT}/requests/${linkRequestOID}`);
    }
}
