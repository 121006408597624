import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import type { LinkRequest, LinkRequestOID } from '../../models/LinkRequest';
import { LinkRequestsActions } from '../actions/link-requests';

export interface LinkRequestsState {
    linkRequests: Record<LinkRequestOID, LinkRequest>;
}

export const LINK_REQUESTS_INITIAL_STATE: LinkRequestsState = {
    linkRequests: {},
};

export default createReducer(
    LINK_REQUESTS_INITIAL_STATE,

    on(
        LinkRequestsActions.loadLinkRequests,
        (state, { linkRequests }): LinkRequestsState => ({
            ...state,
            linkRequests: Object.fromEntries(
                linkRequests.map((linkRequest) => [linkRequest.linkRequestOID, linkRequest]),
            ),
        }),
    ),

    on(LinkRequestsActions.addLinkRequest, (state, { linkRequest }): LinkRequestsState => {
        const linkRequests = { ...state.linkRequests };
        linkRequests[linkRequest.linkRequestOID] = linkRequest;
        return { ...state, linkRequests };
    }),

    on(LinkRequestsActions.replaceLinkRequest, (state, { linkRequestOID, linkRequest }): LinkRequestsState => {
        const linkRequests = { ...state.linkRequests };
        delete linkRequests[linkRequestOID];
        linkRequests[linkRequest.linkRequestOID] = linkRequest;
        return { ...state, linkRequests };
    }),

    on(LinkRequestsActions.deleteLinkRequest, (state, { linkRequestOID }): LinkRequestsState => {
        if (!state.linkRequests[linkRequestOID]) {
            return state;
        }

        const linkRequests = { ...state.linkRequests };
        delete linkRequests[linkRequestOID];
        return { ...state, linkRequests };
    }),
);

export const getLinkRequestsState = createFeatureSelector<LinkRequestsState>('link-requests');
