import { createActionGroup, emptyProps, props } from '@ngrx/store';
import type { LinkRequest, LinkRequestOID } from '../../models/LinkRequest';

export const LinkRequestsActions = createActionGroup({
    source: 'Link Requests',
    events: {
        loadLinkRequests: props<{ linkRequests: LinkRequest[] }>(),
        addLinkRequest: props<{ linkRequest: LinkRequest }>(),
        replaceLinkRequest: props<{ linkRequestOID: LinkRequestOID; linkRequest: LinkRequest }>(),
        deleteLinkRequest: props<{ linkRequestOID: LinkRequestOID }>(),
    },
});

export const LinkRequestsPageActions = createActionGroup({
    source: 'Link Requests Page',
    events: {
        opened: emptyProps(),
    },
});
