import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import type { LinkRequest, LinkRequestOID } from '../../models/LinkRequest';
import type { LinkRequestCommand } from '../../models/LinkRequestCommand';
import { HttpLinkRequestsGateway } from '../../services/HttpLinkRequestsGateway';
import { LinkRequestsResponse } from '../../services/LinkRequestsResponse';
import { LinkRequestsActions } from '../actions/link-requests';

@Injectable()
export class LinkRequestsService {
    constructor(
        private store: Store<AppState>,
        private linkRequestsGateway: HttpLinkRequestsGateway,
    ) {}

    getLinkRequests(): Observable<LinkRequest[]> {
        return this.linkRequestsGateway.getLinkRequests().pipe(
            map(({ results }: LinkRequestsResponse) => results),
            tap((linkRequests: LinkRequest[]) => {
                this.store.dispatch(LinkRequestsActions.loadLinkRequests({ linkRequests }));
            }),
        );
    }

    createLinkRequest(linkRequestCommand: LinkRequestCommand): Observable<LinkRequest> {
        return this.linkRequestsGateway.createLinkRequest(linkRequestCommand).pipe(
            tap((linkRequest: LinkRequest) => {
                this.store.dispatch(LinkRequestsActions.addLinkRequest({ linkRequest }));
            }),
        );
    }

    resendLinkRequest(linkRequestOID: LinkRequestOID): Observable<LinkRequest> {
        return this.linkRequestsGateway.resendLinkRequest(linkRequestOID).pipe(
            tap((linkRequest: LinkRequest) => {
                this.store.dispatch(LinkRequestsActions.replaceLinkRequest({ linkRequestOID, linkRequest }));
            }),
        );
    }

    deleteLinkRequest(linkRequestOID: LinkRequestOID): Observable<void> {
        return this.linkRequestsGateway.deleteLinkRequest(linkRequestOID).pipe(
            tap(() => {
                this.store.dispatch(LinkRequestsActions.deleteLinkRequest({ linkRequestOID }));
            }),
        );
    }
}
